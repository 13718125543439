import React from "react";

const BussinesCard = () => {
  const bussinesCardArray = [
    {
      heading: "MOBILE-OPTIMIZED",
      desc: "Designed to be run on individual devices, or a central hub, PeggyAI provides flexibility for any company.",
    },
    {
      heading: "CLOSE-LOOP ACCOUNTABILITY ",
      desc: "Due to safety being mandatory before clock-in, employees must be compliant before beginning the work day.",
    },
    {
      heading: "GPS ACCURATE LOCATION AND TIME TRACKING",
      desc: "GPS allows PeggyAI to know exactly where employees are, and how long they have worked.",
    },
    {
      heading: "EQUIPMENT TRACKING",
      desc: "PeggyAI monitors equipment usage, location, and maintenance to prevent workers from using equipment they are not trained for.",
    },
    {
      heading: "HEAT/NIGHT SAFETY",
      desc: "Through GPS technology, PeggyAI will always be aware of the local time and temperature, providing the proper night/heat safety prior to any needed shift.",
    },
    {
      heading: "JOB COSTING",
      desc: "Advanced tracking of  equipment, location, and man-hours means that you know exactly where you are making or losing money. ",
    },
  ];
  return (
    <>
      <div className="bg-[#F5F6F7]">
        <div className="max-w-[1140px] px-4 m-auto pt-24">
          <div className="flex justify-center">
            <div className="max-w-[750px] font-Montserrat text-black font-bold text-[26px] md:text-[46px] text-center">
              Keep your business efficient and compliant with our powerful tools
            </div>
          </div>
          <div className="flex flex gap-4 flex-wrap justify-center md:justify-between px-8 lg:px-20 pt-[49px] pb-[150px]">
            {bussinesCardArray.map((data) => {
              return (
                <>
                  <div className="w-[251px] px-7 h-[250px] rounded-[10px] bg-white shadow-[0_4px_10px_0px_rgba(0,0,0,25%)]">
                    <div className="text=black pt-2 font-Montserrat font-bold text-lg text-center h-[100px] flex justify-center flex-col items-center]">
                      {data.heading}
                    </div>
                    <div className="text-center font-Montserrat font-normal text-sm text-black">
                      {data.desc}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BussinesCard;
