import React from "react";
import footerimg from "../../asstes/images/footerimg.png";

const Footer = () => {
  return (
    <>
      <div className="bg-black" id="contact">
        <div className="max-w-[1440px] px-4 m-auto pt-[54px] pb-[88px]">
          <div className="flex flex-col md:flex-row gap-8  items-center sm:gap-16">
            <div>
              <img src={footerimg} />
            </div>
            <div>
              <div className="font-bold font-Roboto text-[20px] md:text-[30px] text-white">
                Contact us!
              </div>
              <div className="max-w-[535px] font-Roboto font-normal pt-[10px]  md:pt-[16px] text-sm text-white">
                Want more info? Schedule an assessment, or contect me directly!
                My name is Ethan Beck, I am a Cal Poly graduate and CEO of
                PeggyAI. The following is my personal contact information. Feel
                free to give me a call or shoot me an email with any questions
                or concerns.
              </div>
            </div>
            <div className="flex flex-col  font-Roboto gap-2">
              <div className="flex gap-2 items-center">
              <div className="font-semibold  text-sm md:text-md text-white">Email:</div>
              <div className="text-white text-xs md:text-sm font-normal">
                  ethanbeck@peggy.ai
                </div>
              </div>
              <div className="flex gap-2 items-center">
              <div className="font-semibold  text-sm md:text-md text-white">
                  Phone number:
                </div>
                <div className="text-white text-xs md:text-sm font-normal">
                  {" "}
                  (805) 441-3764
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="font-semibold  text-sm md:text-md text-white">
                  Mailing address:{" "}
                </div>
                <div className="text-white text-xs md:text-sm font-normal">
                  5255 Traffic Way, Atascadero, CA, 93422
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
