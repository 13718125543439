import React from "react";
import stepbg from "../../asstes/images/stepbg.png";

const StepToday = () => {
  return (
    <>
      <div
        className="h-[450px] md:h-[580px] bg-cover bg-center w-full"
        style={{ backgroundImage: `url(${stepbg})` }}
        id="started"
      >
        <div className="max-w-[1440px] px-4 m-auto">
          <div className="text-white font-Roboto font-bold text-[35px]   md:text-[45px] pt-[60px] md:pt-[144px]">
            Take the first step today.
          </div>
          <div className="text-white font-Roboto font-normal max-w-[435px] text-[18px]">
            Our team is here to help you systemize your business. Put processes
            in place that run with or without you. Processes that are run on
            time, every single time, in exactly the same way. It’s time to take
            the next step in your business.{" "}
          </div>
          <div>
            <a href="https://forms.gle/iPrvb8FnSXxVK3Ub9" 
            className="mt-4 font-Roboto  bg-white w-[189px] no-underline flex text-center justify-center cursor-pointer items-center h-[40px] text-black font-medium text-base">
            Get an assessment
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepToday;
