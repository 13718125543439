import React, { useState } from "react";
import Header from "../Header/header";
import bg from "../../asstes/images/bg.png";
import logo from "../../asstes/images/logo.png";
import ProvenSystem from "../ProvenSystem";
import ListenClients from "../ListenClients";
import BussinesCard from "../BusinessCard";
import StepToday from "../StepToday";
import Footer from "../Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose } from "react-icons/cg";
import { MdEmail } from "react-icons/md";
import { useRef } from "react";
import { logoHeader, headerLineHeight, loginLink } from "../../index.css";

const Home = () => {
  const resultRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleScrollWhatIsGlobX = (e) => {
    e.preventDefault();
    resultRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="bg-black">
        <div className="header-wrapper h-[75px] font-inter bg-black max-w-[1440px] m-auto flex justify-between   text-white items-center px-4 hidden md:flex">
          <div className="flex gap-4 text-white font-extrabold text-lg headerLineHeight">
            <div className="cursor-pointer">
              {" "}
              <img
                className="logoHeader min-h-fit max-h-20"
                src={logo}
                alt="logo"
              ></img>
            </div>
            <div className="cursor-pointer">
              {" "}
              <a className="text-white font-inter no-underline" href="#about">
                ABOUT
              </a>
            </div>
            <div className="cursor-pointer">
              <a className="text-white font-inter no-underline" href="#client">
                OUR CLIENTS
              </a>
            </div>
            <div className="cursor-pointer font-inter">
              <a className="text-white no-underline" href="#started">
                GET STARTED
              </a>
            </div>
            <div className="cursor-pointer">
              <a className="text-white no-underline" href="#contact">
                CONTACT US
              </a>
            </div>
          </div>
          <a className="loginLink" href="https://my.peggy.ai/login.php">
            <div className="flex gap-4">
              <div className="text-white w-[113px] font-inter h-[32px] text-xs flex justify-center items-center text-center cursor-pointer bg-[#2B2B2B]">
                Login{" "}
              </div>
            </div>
          </a>
        </div>

        <div className="flex text-white cursor-pointer h-[65px] text-[30px] max-w-[1440px] m-auto justify-end px-4 sm:flex md:hidden pt-3">
          <GiHamburgerMenu onClick={handleShow} />
        </div>

        <Offcanvas
          show={show}
          onHide={handleClose}
          className="bg-black "
          scroll={true}
          style={{ backgroundColor: "#F5F5F5", width: "60%" }}
        >
          <Offcanvas.Header>
            <Offcanvas.Title>
              <div className="flex gap-32 justify-between items-end w-full  text-2xl px-3">
                <div></div>
                <CgClose
                  style={{ color: "white" }}
                  onClick={() => handleClose()}
                />
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="flex flex-col gap-3 text-white font-inter font-extrabold text-sm">
              <div className="cursor-pointer">
                <a
                  className="text-white no-underline"
                  href="#about"
                  onClick={() => handleClose()}
                >
                  ABOUT
                </a>
              </div>
              <div className="cursor-pointer" onClick={() => handleClose()}>
                <a className="text-white no-underline" href="#client">
                  OUR CLIENTS
                </a>
              </div>
              <div className="cursor-pointer">
                <a className="text-white no-underline" href="#started">
                  GET STARTED
                </a>
              </div>
              <div className="cursor-pointer" onClick={() => handleClose()}>
                <a className="text-white no-underline" href="#contact">
                  CONTACT US
                </a>
              </div>
            </div>
            <div className="flex justify-start mt-4 items-center px-1">
              <div className="h-[50px] text-black text-xl w-[250px]  bg-[white] text-center items-center flex justify-center">
                <a
                  className="loginLink text-black"
                  href="https://my.peggy.ai/login.php"
                >
                  <div className="flex gap-4">Login </div>
                </a>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div
        className="h-[540px] md:h-[666px] bg-cover bg-center w-full"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="max-w-[1440px] m-auto px-4">
          <div className="font-bold font-Roboto text-[35px] md:text-[62px] text-white max-w-[755px] pt-10 md:pt-16">
            Make your business run smarter with PeggyAI
          </div>

          <div className="pt-3 font-Roboto  max-w-[435px] text-white text-[16px] md:text-[22px] font-normal">
            At some point, entrepreneurs find themselves chained to their companies. PeggyAI helps systemize your business and free you from these chains. Learn how today!
          </div>
          <div>
            <a href="https://forms.gle/iPrvb8FnSXxVK3Ub9"
              className="mt-4 font-Roboto  bg-white w-[189px] no-underline flex text-center justify-center cursor-pointer items-center h-[40px] text-black font-medium text-base">
              Get an assessment
            </a>
          </div>
        </div>
      </div>
      <div>
        <ProvenSystem />
      </div>
      <div>
        <ListenClients />
      </div>
      <div>
        <BussinesCard />
      </div>
      <div>
        <StepToday />
      </div>
      <div>
        <Footer ref={resultRef} />
      </div>
    </>
  );
};

export default Home;
