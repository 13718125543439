import React from "react";
import ReactPlayer from "react-player";

const ListenClients = () => {
  return (
    <>
      <div className="flex justify-center px-4" id="client">
        <div className="max-w-[600px] text-center text-black font-Montserrat font-bold text-[36px] sm:text-[46px] pt-9">
          Learn how PeggyAI can help you
        </div>
      </div>

      <div className="flex flex-col justify-center items-center sm:flex-row gap-10 sm:gap-24 max-w-[1440px] m-auto px-4 sm:justify-center pt-16 pb-[92px]">
        <div className=" h-[300px] lg:h-[400px] xl:h-[483px] w-[300px] sm:w-[350px] lg:w-[400px]  xl:w-[491px]">
          <ReactPlayer
            controls="true"
            width="100%"
            height="100%"
            url="https://youtu.be/GidoH9JB5Rc"
          />
        </div>
        <div className=" h-[300px] lg:h-[400px] xl:h-[483px] w-[300px] sm:w-[350px] lg:w-[400px]  xl:w-[491px]">
          <ReactPlayer
            controls="true"
            width="100%"
            height="100%"
            url="https://youtu.be/cd4ya5KtEyU"
          />
        </div>
      </div>
    </>
  );
};

export default ListenClients;
