import React from "react";
import cc1 from "../../asstes/images/cc1.png";
import cc2 from "../../asstes/images/cc2.png";
import cc3 from "../../asstes/images/cc3.png";
import cc4 from "../../asstes/images/cc4.png";

const ProvenSystem = () => {
  const ProvenArray = [
    {
      img: cc1,
      heading: "SYSTEMS",
      desc: "Build a business that runs smoothly, with or without you.",
    },
    {
      img: cc2,
      heading: "PROCESSES",
      desc: "Create personalized closed-loop processes.",
    },
    {
      img: cc3,
      heading: "EMPLOYEES",
      desc: "Keep your employees safe and accountable.",
    },
    {
      img: cc4,
      heading: "ADAPTABILITY",
      desc: "Get the job done anytime, anywhere.",
    },
  ];
  return (
    <>
      <div className="bg-black" id="about">
        <div className="max-w-[1440px] pt-24 m-auto px-4">
          <div className="max-w-[515px] pt-12 font-Montserrat font-bold text-white text-[26px] md:text-[46px]">
            Proven Systems. Automatic Solutions.
          </div>
          <div className="max-w-[500px] font-Montserrat pt-6 md:pt-10 text-lg text-[#FBFBFD] font-normal">
            We combine time tested systems with closed-loop accountability
            software to free businesses from their daily grind.
          </div>
          <div className="flex flex-wrap gap-5 justify-center md:justify-center pt-12 md:pt-20 pb-40">
            {ProvenArray.map((data) => {
              return (
                <>
                  <div className="h-[300px] w-[280px] bg-white">
                    <div>
                      <img src={data.img} className="w-[280px]" />
                    </div>
                    <div className="pt-[13px] px-[25px]">
                      <div className="text-black text-lg font-Montserrat">{data.heading}</div>
                      <div className="text-black text-sm pt-[13px] font-Montserrat">
                        {data.desc}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProvenSystem;
